import React, {useEffect, useState} from 'react';
import {Button, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import TableHeader from './TableHeader';
import {TableSetting} from '../../types';
import TableMobile from './TableMobile';
import * as _ from 'lodash';

type Props = {
  tableName?: string;
  columns: ColumnsType<any>;
  data: any[];
  loading?: boolean;
  filter?: JSX.Element[];
  onClickRow?: (data: any) => void;
  reloadData?: () => void;
  onSearch?: () => void;
  headerWidgers?: JSX.Element[];
};

const DataTable: React.FC<Props> = ({
  tableName,
  columns,
  data,
  loading = false,
  filter,
  onClickRow,
  reloadData,
  onSearch,
  headerWidgers,
}) => {
  const [tableSettings, setTableSettings] = useState([] as TableSetting[]);
  const [openSettings, setOpenSettings] = useState(false);
  const toggleSetting = (value: boolean) => setOpenSettings(value);

  useEffect(() => {
    if (_.isEmpty(tableSettings)) {
      setTableSettings(columns.map((c) => ({column: c, show: true})));
    }
  }, [columns]);

  const onRowClick = (d: any) => {
    if (onClickRow != null) {
      onClickRow(d);
    }
  };

  return (
    <>
      <Table
        loading={loading}
        columns={tableSettings.filter((s) => s.show).map((s) => s.column)}
        dataSource={data.map((d) => ({...d, key: d.uid}))}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              onRowClick(record);
            },
          };
        }}
        bordered
        title={() => (
          <div>
            <TableHeader
              tableName={tableName || ''}
              headerWidgers={headerWidgers}
              reloadData={reloadData}
            />
            {filter != null && (
              <div className="p-4 flex flex-wrap gap-3">
                {filter}
                <Button
                  key="submit"
                  shape="round"
                  type="primary"
                  size="small"
                  onClick={onSearch}
                >
                  Хайх
                </Button>
              </div>
            )}
            <TableMobile
              data={data.map((d) => ({...d, key: d.uid}))}
              columns={tableSettings.filter((s) => s.show).map((s) => s.column)}
              onClick={onClickRow}
            />
          </div>
        )}
      />
    
    </>
  );
};

export default DataTable;
