


export type ApiResponse = {
  status: boolean;
  message: string;
  data: any | undefined;
  code: string;
};


export enum OtpType {
  REGISTER = 'register',
  PASSWORD_FORGOT = 'password_forgot',
}

