import React, { useState } from 'react';
import { Button, Form, Input, Modal, Alert, message, Select } from 'antd';
import * as _ from 'lodash';
import { saveData } from '../../helpers/firestore';
// import LogoImage from '../../assets/images/logo.png';
import { CopyOutlined } from '@ant-design/icons';
import { firestore } from '../../firebase';
type Props = {
  uid: string;
  open?: boolean;
  toggle: (value: boolean) => void;
};

const xbetPromo = '1x_2069572'
const melbetPromo = 'agentwaz'
const betTypes = [
  {
    "label": "1xbet",
    "value": "1xbet"
  },
  {
    "label": "Melbet",
    "value": "melbet"
  },


]

const AccountAdd: React.FC<Props> = ({ uid, open = false, toggle }) => {
  const [name, setName] = useState('');

  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const onCancel = () => {
    setName('');

    setLoadingSave(false);
    toggle(false);
  };

  const clipboardwriteText = (value: string) => {
    navigator.clipboard.writeText(value);
    messageApi.info('Амжилттай хуулагдлаа!')
  }

  const onSave = async (values:any) => {
    console.log("onSave")
    setLoadingSave(true);
    let result = {
      status: true,
      error: ''
    }
    const user = await firestore.collection(`wazsConfig`).where('accountID', '==', values.accountID).where('type','==',values.type).get();
    if (user.docs.length == 0) {

      const res = saveData(`wazs/${uid}/depositAccounts`, undefined, {
      ...values

      }, true)




    } else {
      const errorMsg = `${name} бүртгэлтэй байна! Өөр ID бүртгүүлнэ үү`
      result.status = false
      result.error = errorMsg
      setError(errorMsg)
    }

    if (!result.status) {
      setLoadingSave(false);
      messageApi.open({
        type: 'error',
        content: result.error,
      });
    } else {
      onCancel();
      messageApi.open({
        type: 'success',
        content: 'Account Number хадгалагдлаа.',
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Account Number нэмэх"
        onCancel={onCancel}
        className="info-modal"
        centered
        footer={[]}
     
      >
        <div className="dialog-body">
          <div className="mt-4 flex justify-center">
            {/* <img alt="logo" src={LogoImage} height={140} width="auto" /> */}
          </div>
          <div className="dialog-content px-8">
            <Alert message="Анхааруулга" description={
              <>
              🇺🇸 Personal profile &gt; Account Number id: xxxxxxxxxxx <br />
              🇲🇳 Монголоор ашигладаг бол: Хувийн профайл &gt;  Дансны дугаар id: xxxxxxxxxxx
              </>
              } type="warning" showIcon />
               <Alert message="Промо код 🎉" description={
                  <>
                    
                      <div className="mt-1" onClick={() => { clipboardwriteText(`${xbetPromo}`) }}>
                        <h2>1xbet:{xbetPromo} <CopyOutlined /></h2>
                      </div>

                      <div className="mt-1" onClick={() => { clipboardwriteText(`${melbetPromo}`) }}>
                        <h2>Melbet:{melbetPromo} <CopyOutlined /></h2>
                        </div>
                  </>
                } type="success" showIcon />
            <br />
            <Form
              form={form}
              onFinish={onSave}
              scrollToFirstError
            >

              <Form.Item
                name="type"
                rules={[{
                  required: true,
                  message:'Энд дарж 1xbet Melbet ээ сонгоорой'
                }]}>
                <Select options={betTypes}
                  placeholder='Энд дарж 1xbet Melbet сонгох'

                />
              </Form.Item>
              <Form.Item
                name="accountID"
                rules={[{
                  required: true,
                       message:'Account Number id оруулна уу'

                }]}>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Account Number id  "
                  className="mb-3"
                  type='number'
                  minLength={9}
                  maxLength={9}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loadingSave}>
                  Хадгалах
                </Button>
              </Form.Item>
            </Form>

            {!_.isEmpty(error) && <Alert message={error} type="error" />}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountAdd;
