

import XbetGirl from '../assets/images/1xbetgirl.png';
import XbetNoRisk from '../assets/images/1xbetnorisk.png';

const XbetMedias = () => {

  return (
    <div>
      <a href='https://affpa.top/L?tag=d_3665424m_97c_&site=3665424&ad=97' target='_blank'>
        <img alt="logo" src={XbetGirl} height={150} width="auto" style={{ margin: 10 }} />

      </a>
      <a href='https://mn-1xbet.com/mn/promotions/no-risk-bet' target='_blank'>
        <img alt="logo" src={XbetNoRisk} height={150} width="auto" style={{ margin: 10 }} />

      </a>

    </div>);
};

export default XbetMedias;
