

import FDepo from '../assets/images/melbet/firstdepo.png';
import XbetNoRisk from '../assets/images/1xbetnorisk.png';

const MelbetMedias = () => {

  return (
    <div>

      <a  href='https://refpakrtsb.top/L?tag=d_3666363m_66335c_&site=3666363&ad=66335'  target='_blank'>
      <img alt="logo" src={FDepo} height={150} width="auto" style={{margin:10}}/>

      </a>

      {/* <a  href='https://mn-1xbet.com/mn/promotions/no-risk-bet'  target='_blank'>
      <img alt="logo" src={XbetNoRisk} height={150} width="auto" style={{margin:10}}/>

      </a> */}
      
   
    </div>);
};

export default MelbetMedias;
