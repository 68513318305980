import React, {useState} from 'react';
import type {MenuProps} from 'antd';
import {Dropdown} from 'antd';
import {logout} from '../../helpers/firebase_auth';

type Props = {
  email: String;
};
const AccountDropDown: React.FC<Props> = ({email}) => {
  const [loading, setLoading] = useState(false);
  // const [changePassOpen, setChangePassOpen] = useState(false);
  // const toggleChangePass = () => setChangePassOpen(!changePassOpen);

  const signOut = async () => {
    if (loading) return;
    setLoading(true);
    logout();
  };

  const items: MenuProps['items'] = [
    {
      key: '2',
      label: (
        <div className="flex items-center border-solid border-b-surface border-t-0 border-x-0 px-3 py-2">
          <i className="bx bx-user"></i>
          <div className="ml-3 text-md">{email}</div>
        </div>
      ),
    },
    // {
    //   key: '3',
    //   label: (
    //     <div className="px-3 my-1">
    //       <a href="#" className="text-black" onClick={toggleChangePass}>
    //         Нууц үг солих
    //       </a>
    //     </div>
    //   ),
    // },
    {
      key: '4',
      className: 'text-error',
      onClick: () => {
        signOut();
      },
      label: (
        <div className="px-3 my-1">
          <div className="text-error w-full">Системээс гарах</div>
        </div>
      ),
    },
  ];

  return (
    <Dropdown menu={{items}} placement="bottomRight">
      <div className="flex cursor-pointer">
        <div className="acc_dropdown">
          <i className="bx bx-user"></i>
        </div>
        <div className="mx-3 flex flex-col justify-center">
          <div className="text-md">{email}</div>
        </div>
      </div>
    </Dropdown>
  );
};

export default AccountDropDown;
