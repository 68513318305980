import * as _ from 'lodash';
import {useContext} from 'react';
import {Navigate} from 'react-router-dom';
import LoadingComponent from '../components/Loading';
import {LoadingContext, UserContext} from '../context/app.contexts';
import NotFound from '../pages/NotFound';
import {Routes} from './route_helper';

type AuthMiddlewareProps = {
  isAuthProtected: boolean;
  children: JSX.Element;
};

const AuthMiddleware = ({isAuthProtected, children}: AuthMiddlewareProps) => {
  const loadingPage = useContext(LoadingContext);
  const {user, userRole, loading} = useContext(UserContext);

  if (isAuthProtected) {
    if (loadingPage || loading) return <LoadingComponent />;
    else if (!user && !loading) return <Navigate to={Routes.login} />;
    else if (_.isEmpty(userRole) )
      return <NotFound />;
    // return <LoadingComponent />;
    else return children;
  } else {
    if (loadingPage || loading) {
      return <LoadingComponent />;
    } else return children;
  }
};

export default AuthMiddleware;
