import * as _ from 'lodash';
import {ColumnsType} from 'antd/es/table';
import {Key, ReactNode} from 'react';

type Props = {
  columns: ColumnsType<any>;
  data: any[];
  onClick?: (data: any) => void;
};

const TableMobile: React.FC<Props> = ({columns, data, onClick}) => {
  const getTopFieldId = () => {
    return _.isEmpty(columns) ? '' : columns[0].key || '';
  };

  const getFields = () => {
    return columns.length > 1 ? columns.slice(1) : [];
  };

  const getCell = (d: any, fieldId: Key) => {
    const cell = columns.find((c) => c.key === fieldId);
    return (
      <div className={fieldId === 'action' ? 'col-12' : 'col-6'}>
        <div className="text-gray text-sm">{cell?.title?.toString()}</div>
        <div className="overflow-hidden">
          {cell?.render != null
            ? (cell?.render(d[fieldId], d, 1) as ReactNode)
            : d[fieldId] || ''}
        </div>
      </div>
    );
  };

  const onRowClick = (d: any) => {
    if (onClick != null) {
      onClick(d);
    }
  };

  return (
    <div className="mobile-container block sm:hidden">
      {_.isEmpty(data) && <div className="mobile-row text-gray">No data</div>}
      {data.map((d) => {
        return (
          <div key={d.key} className="mobile-row">
            <div
              className="font-medium text-link"
              onClick={() => onRowClick(d)}
            >
              {getCell(d, getTopFieldId())}
            </div>
            {Array.from({length: Math.ceil(getFields().length / 2)}).map(
              (r, i) => {
                var fields = getFields();
                var fieldId1 = fields[2 * i].key || '';
                var fieldId2 =
                  fields.length > 2 * i + 1 ? fields[2 * i + 1].key : null;
                return (
                  <div
                    key={`mobile-${d.key}-row-${i}`}
                    className="grid grid-cols-2 mt-2 gap-x-3"
                  >
                    {getCell(d, fieldId1)}
                    {fieldId2 != null && getCell(d, fieldId2)}
                  </div>
                );
              },
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TableMobile;
