import {functions} from '../firebase';
import * as _ from 'lodash';


export const manageDeposit = async (depositId: string,type:number) => {
  console.log(depositId,type)
  try {
    const data = await functions.httpsCallable('manageDeposit')({
      depositId:depositId,
      type:type
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};


export const checkKhanBank = async (accountID:string) => {
  try {
    const data = await functions.httpsCallable('checkKhanTrans')({

      accountID:accountID
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};
export const makeKhanWithdraw = async (withdrawID:string) => {
  try {
    const data = await functions.httpsCallable('makeKhanWithdraw')({"withdrawID":withdrawID});
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const appOTP = async (phone:string,type:string) => {
  try {
    const data = await functions.httpsCallable('appOTP')({

      phone:phone,
      type:type
    });
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const appConfirmOTP = async (payload:any) => {
  try {
    const data = await functions.httpsCallable('appOTPConfirm')(payload);
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const appUserRegister = async (payload:any) => {
  try {
    const data = await functions.httpsCallable('appUserRegister')(payload);
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

export const mobWithDraw= async (payload:any) => {
  try {
    const data = await functions.httpsCallable('mobWithDraw')(payload);
    return _.get(data, 'data');
  } catch (e: any) {
    return {status: false, msg: e.toString()};
  }
};

