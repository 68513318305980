import {Timestamp} from 'firebase/firestore';

export enum AuthRoles {
  ADMIN = 'admin',
  FINANCIAL_INSTITUTE = 'financial_institute',
  SHOP = 'shop',
  ANONYMOUS = 'anonymous',
}

export const AUDIT_ADMIN = 'audit_admin';

export type RouteModel = {
  path: string;
  component: () => JSX.Element;
};

export enum LoanStatus {
  loanCreated = 'loanCreated',
  sentRequest = 'sentRequest',
  rejected = 'rejected',
  accepted = 'accepted',
}

export enum RequestStatus {
  created = 'created',
  sent = 'sent',
  verified = 'verified',
  rejected = 'rejected',
}

export enum PaymentStatus {
  SUCCESS = 'SUCCESS',
  DECLINED = 'DECLINED',
}

export type UserModel = {
  uid: string;
  email: string;
  phone: string;
  userType: string;
  active?: boolean;
  registerNumber: string;
  companyName: string;
  firstname: string;
  lastname: string;
  gender: string;
  thumbnail: string;
  city: string;
  cityCode: string;
  district: string;
  street: string;
  ntDugaar: string;
  createdAt: Timestamp;
};

export type OtpModel = {
  type: string;
  phone: string;
  otpCode: number;
  isVerified: boolean;
  createdAt: Timestamp;
};

export type NotificationModel = {
  uid: string;
  region?: string;
  regionCode?: string;
  title: string;
  desc: string;
  status: string;
  type: string;
  sentAt: Timestamp | undefined;
  createdAt: Timestamp;
};


export type AdminModel = {
  email: string;
  createdAt: Timestamp;
};

export type CertificateModel = {
  uid: string;
  name: string;
  desc: string;
  detail: string;
  image: string;
  logo: string;
  groups: FormGroup[];
  applicationGroups: FormGroup[];
  selfEvaluation: boolean;
};

export type FormGroup = {
  id: number;
  name: string;
};



export type WazMelbetModel = {
  uid: string;
  accountID: string;
  type:string;
  lvl: string;
  createdAt: Timestamp;
};
export type WithdrawModel = {
  uid: string;
  status: string;
  statusMgs:string;
  code:string;
  
  amount:number;
  bonus:number;
  createdAt: Timestamp;
};
export type DepositModel = {
  uid: string;
  lvl:number;
  status: string;
  statusMgs:string;
  accountId:string;
  bonus:number;
  amount:{
    amount:number
  }
  createdAt: Timestamp;
};



export type TableSetting = {
  column: any;
  show: boolean;
};

