import {compile} from 'path-to-regexp';

export const Routes = {
  dashboard: '/',
  xbet: '/1xbet',
  melbet: '/melbet',
  deposits: `/deposits/:id/:type/:accountID`,
  withdraws: `/withdraws/:id/:type/:accountID`,
  //auth
  login: '/login',
  register:'/register',
  forgot:'/forgot',
  notfound: '/notfound',
  compilePath(path: string, param: object) {
    const toPath = compile(path, {encode: encodeURIComponent});
    return toPath(param);
  },
};
