import {AuthRoles} from '../types';

export const isAllowedRole = (role: AuthRoles) => {
  return [
    AuthRoles.ADMIN,
    AuthRoles.FINANCIAL_INSTITUTE,
    AuthRoles.SHOP,
  ].includes(role);
};

export const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const formFieldTypes = [
  {
    value: 'textarea',
    label: 'Текст',
  },
  {
    value: 'single_choice',
    label: '1 сонголт',
  },
  {
    value: 'multi_choice',
    label: 'Олон сонголт',
  },
  {
    value: 'plant_selection',
    label: 'Таримал сонгох',
  },
];
